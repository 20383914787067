import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/app/utils/font/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../../static/PretendardVariable.ttf\",\"variable\":\"--font-pretendard\"}],\"variableName\":\"pretendard\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/component/client/page/splash/SplashScreen.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/core/ToastProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/provider/ReactQueryProvider.tsx");
